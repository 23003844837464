<template>
  <v-container class="bg-color-theme px-3">
    <v-row>
      <v-col cols="12" md="12">
        <v-card elevation="2" class="py-9 px-4">
          <h6 class="text-center font-14px text-333 font-weight-medium">
            {{ shopName }}
          </h6>
          <v-card-subtitle class="font-12px text-333 px-3 line-height-16"
            >店舗を管理するユーザーを追加できます。<br />
            追加したいユーザーがcross
            pointアプリに会員登録済の場合は「ユーザーを招待する」を、会員登録していない場合は「ユーザーを新規作成する」を選択して必要事項を入力してください。
          </v-card-subtitle>

          <v-btn
            class="mt-3 font-weight-bold"
            block
            color="primary"
            :to="{ name: 'inviteUser' }"
          >
            ユーザーを招待する
          </v-btn>
          <v-btn
            elevation="3"
            outlined
            block
            color="white"
            :to="{ name: 'createUser' }"
            class="primary--text btn-blue-outline mt-3 font-weight-bold"
            >ユーザーを新規作成する</v-btn
          >
        </v-card>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
export default {
  name: "AddUser",
  data() {
    return {
      shopName: ""
    };
  },
  props: ["shop"],
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      await this.$store
        .dispatch("SHOP_GET", { id: this.shop })
        .then(response => {
          let shop = response.data.data.shop;
          this.shopName = shop.store_display_name
            ? shop.store_display_name
            : shop.store_name;
        });
    }
  }
};
</script>
